<template>
    <div class="grid grid-cols-12 gap-4">
        <LoadingComponent :isLoading="loading" />
        <div class="col-span-3">
            <div class="p-5 bg-white rounded-lg shadow-lg">
                <SideBar />
            </div>
            <div v-permission="['create']" class="mt-5 flex justify-center">
                <btn @onClick="$router.push({ name: 'permission_role_set' })">
                    <i class="fas fa-plus"></i>
                    <span class="ml-2">新增角色</span>
                </btn>
            </div>
        </div>
        <div class="col-span-9 rounded-lg relative bg-white">
            <div class="py-2 border-b-2 border-gray-100">
                <ul class="flex py-2 px-5">
                    <li class="flex-1">角色名稱</li>
                    <li v-permission="['update']" class="max-w-[100px] min-w-[100px] text-center">編輯角色</li>
                    <li v-permission="['delete']" class="max-w-[100px] min-w-[100px] text-center">刪除角色</li>
                </ul>
            </div>
            <div class="my-5">
                <ul>
                    <li v-for="(item, index) in datas" :key="index" class="flex py-2 px-5">
                        <div class="flex-1 text-black">
                            {{ item.name }}
                        </div>
                        <button v-permission="['update']" class="min-w-[100px] text-sm text-red-500" @click.stop="onEdit(item.id)">
                            <i class="far fa-edit"></i>
                        </button>
                        <button v-permission="['delete']" class="min-w-[100px] text-sm text-red-500" @click.stop="onDelete(item.id, item.name)">
                            <i class="far fa-trash-alt"></i>
                        </button>
                    </li>
                </ul>
                <div class="justify-center mt-3 flex mb-3">
                    <MyPagination
                        :paginationData="paginationData"
                        :currentPage="currentPage"
                        @onPageSizeChange="pageSizeChange"
                        @onCurrentPageChange="currentPageChange" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SideBar from "../Sidebar.vue";
// 導入自定義 按鈕
import Btn from "@/components/Button.vue";
// 分頁組件
import MyPagination from "@/components/MyPagination.vue";
// 旋轉動畫組件
import LoadingComponent from "@/components/Loading.vue";

export default {
    name: "PermissionRoleList",
    components: {
        LoadingComponent,
        MyPagination,
        SideBar,
        Btn,
    },
    computed: {
        // 統整 getlist api filters
        filterData() {
            return {
                limit: this.paginationData.limit,
                page: this.currentPage,
            };
        },
    },
    data() {
        return {
            titles: ["角色名稱", "編輯角色", "刪除角色"],
            datas: [
                // {
                //     id: 1,
                //     name: "最高管理者",
                //     root: true
                // },
                // {
                //     id: 2,
                //     name: "A級客服"
                // },
                // {
                //     id: 3,
                //     name: "B級客服"
                // },
                // {
                //     id: 4,
                //     name: "C級客服"
                // }
            ],
            paginationData: {
                limit: 10,
                total: 0,
            },
            currentPage: 1,
            loading: false,
        };
    },
    methods: {
        async getList() {
            try {
                const { data } = await this.$api.GetRolesApi(this.filterData);
                this.datas = [...data.data];
                this.paginationData = {
                    limit: data.per_page,
                    total: data.total,
                };
            } catch {
                console.log("取得資料失敗");
            }
        },
        pageSizeChange(val) {
            // 更新每頁取得幾筆資料參數
            this.paginationData.limit = val;
            // 當前頁面設定為第一頁
            this.currentPage = 1;
            this.getList();
        },
        currentPageChange(val) {
            // 設定目前分頁為第幾面
            this.currentPage = val;
            this.getList();
        },
        /**
         * 編輯角色
         * @param { type Number(數字) } id 路由 id
         */
        onEdit(id) {
            this.$router.push({ name: "permission_role_set", params: { id } });
        },
        /**
         * 刪除角色
         * @param { type Number(數字) } id 路由 id
         * @param { type String(字串) } name 路由名稱 name
         */
        async onDelete(id, name) {
            this.loading = true;
            const opts = {
                title: "刪除資料",
                message: `你確認刪除 <span class="text-red-600"> ${name} </span> 角色嗎？`,
            };
            this.$pop
                .popConfirm(opts)
                .then(
                    async () => {
                        await this.$api.DeleteRoleApi(id);
                        this.$message({
                            type: "success",
                            message: "刪除角色成功",
                        });
                        await this.getList();
                    },
                    () => {
                        return;
                    }
                )
                .catch((err) => {
                    this.errorCallback({ err });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
    mounted() {
        this.getList();
    },
};
</script>

<style lang="scss" scoped>
/deep/ .el-tree-node__content {
    height: 50px;
}
.custom-tree-node {
    flex: 2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;
    padding-right: 8px;
}

.el-tree-node__expand-icon .el-icon-caret-right {
}
.treeBox {
    .el-tree {
        @apply w-full rounded-lg;
    }
    @apply w-full rounded-lg;
}
</style>
